import React from 'react'
import Link from 'gatsby-link'
import logo from '../img/7knot.svg'


const Navbar = () => (
  <nav className="navbar is-transparent">
    <div className="navbar-brand">
    <Link to="/" className="">
     <img src={logo} className="navbar-logo" alt=""/>
      <span className="navbar-item navbar-title">
        John Dilworth
      </span>
      <span className="navbar-item navbar-title-mobile">
        JD
      </span>
    </Link>
    </div>

    <div className="navbar-links">
      <Link className="navbar-item navbar-posts" activeClassName="active" to="/articles">
        Posts
      </Link>
      <Link className="navbar-item navbar-trips"  activeClassName="active" to="/trips">
        Trips
      </Link>
      <Link className="navbar-item navbar-collections"  activeClassName="active" to="/collections">
        Artwork
      </Link>
    </div>
  </nav>
)

export default Navbar
