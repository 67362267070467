import {graphql} from 'gatsby'
import Layout from '../components/layout'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import '../layouts/collective-medical.styl';
import Penrod from '../img/penrod.jpg';
import Moll from '../img/moll.jpg';
import ResumeIcon from '../img/resume-icon.svg'
import CollectiveMedicalLogo from '../img/collective-medical-logo.svg'
import CollectiveMedicalLogoFull from '../img/collective-medical-logo-full.svg'
import ExperianAncestry1 from '../img/ancestry1.png'
import ExperianAncestry2 from '../img/ancestry2.png'
import ExperianAncestry3 from '../img/ancestry3.png'
import ExperianAncestry4 from '../img/ancestry4.png'
import Img from 'gatsby-image'

export default class CollectiveMedicalPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.articles
    const { edges: trips } = data.trips
    const { edges: artwork } = data.artwork

    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="collective-medical">
      <div className="page">
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/MyFontsWebfontsKit/MyFontsWebfontsKit.css"/>
      </Helmet>
      <article>

      <div style={{"minHeight":"35vh","backgroundColor":"#eee"}}>
      <section className="contentSection" style={{}}>
        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <header>
          <h1><img id="collective-medical-logo" src={CollectiveMedicalLogo} style={{"verticalAlign":"middle"}}/> + John Dilworth</h1>
          <p className="introText" >I'm John Dilworth and would love to make Collective Medical's products better and more effective by developing and leading a user experience program in your product organization. </p>
        </header>
        </ScrollAnimation>
        </section>
        </div>

        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <section className="contentSection">
          <div className="row">
            <div className="col-xs-12 col-sm-7">
              <h3>How I would help</h3>
              <ul>
              <li className="introText">
              Establish a customer research program that informs design and product decisions. Assist product management organization to identify customer segments and their needs.
              </li>
              <li className="introText">
              Map the customer journey. Audit the current product to understand and identify areas for improvement.
              </li>
              <li className="introText">
              Establish design principles, guidelines, processes, and tools to enable high quality, consistent and efficient design.
              </li>
              <li className="introText">
              Recruit, hire and manage employees or contractors to augment research and design (as needed).
              </li>
              <li className="introText">
              Lead and contribute to the design of Collective Medical's products. Create best-in-class interfaces that are accessible, legible, easy to use, and effective.
              </li>
              <li className="introText">
              Work with Collective Medical's leadership to identify, prototype and validate future product concepts.
              </li>
              </ul>

              </div>

              <div className="col-xs-12 col-sm-5">
              <ScrollAnimation animateIn="fadeInRight" duration={1}>
              <h3>About Me</h3>
              <ul>
              <li>20+ years experience</li>
              <li>10+ years of experience in management roles (org size up to 20 employees)</li>
              <li>I know how to hire and grow great talent</li>
              <li>Experience working in-house design and with a design agency</li>
              <li>Deep understanding of design process and software and mobile development</li>
              <li>I can design, code, write and am happy to do any of it</li>
              <li>I want to build great products and work with the best people</li>
              </ul>
              <p><Link to="/resume" className="" ><img src={ResumeIcon} alt="" style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>View my resume</strong></Link></p>
              </ScrollAnimation>
            </div>
          </div>
          </section>
          </ScrollAnimation>

          <section className="mt-100 testimonial">
            <div className="testimonial-wrapper">
            <ScrollAnimation animateIn="fadeIn" duration={.5}>
              <div className="testimonial">
                <p>“You will see lots of articles about hiring designers that say stuff like, “Full-stack designers don’t exist! Quit looking for unicorns! Respect specialization!” That’s because the authors haven’t worked with John. From visual design, customer research, solving intense interaction design problems, reworking challenging information architectures, writing, all the way to coding up design tools that give the team superpowers. He does it all and does it all well.”</p>
              <p>“If I didn’t love the guy so much, I would hate him out of jealousy.”</p>
              </div>

              <div className="testimonial-caption">
              <div className="avatar"><img alt="Josh Penrod" src={Penrod}/></div>
              <p className="testimonial-caption"><strong>Josh Penrod</strong><br/>Chief Product Officer at Podium<br/>Formerly VP of UX at Ancestry</p>
              </div>

            </ScrollAnimation>
            </div>
          </section>

          <section className="contentSection">
            <div >
              <div className="row">
                <div className="col-xs-12 col-sm-9 p-40">
                  <h1 className="">Case Studies</h1>
                  <p className="">I’ve hand-picked a few case studies to help give you an idea of the work that I’ve accomplished over my years at Ancestry. In addition to directly working on product interfaces and concepts, I’ve been heavily involved in developing company strategy, hiring, and improving our product design discipline.</p>
                </div>
              </div>
              <div className="row mt-60">
                <div className="col-sm-8 col-xs-12">
                  <Link to="/casestudy/ancestry-interfaces/" className="" >
                    <h2 className="experian-heading"><span className="numbers">1</span> Evolving Ancestry Towards Consistency</h2>
                    <p>Over my years at Ancestry, I’ve been able to develop and evolve the interface, incorporating Ancestry’s new & popular DNA product and it’s long-standing family tree product into a unified design. I’ve also radically transformed the way many of the primary interfaces function, allowing users to build their family trees more efficiently.
                    </p>
                    <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                  </Link>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <ScrollAnimation animateIn="fadeInRight" duration={1}>
                    <img  alt="" src={ExperianAncestry1} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>

              <Link to="/casestudy/ancestry-bonsai/" className="" >
              <div className="row">
                <div className="col-sm-8 col-xs-12 last-md last-sm">
                  <h2 className="experian-heading"><strong><span className="numbers">2</span> Updating and Maintaining Ancestry’s Interface</strong></h2>
                  <p>Ancestry has been an online company since 1996. Over the past three years, I led a continuous effort to maintain and update the design.</p>
                  <p>This effort included the development of a pattern library, improvements in accessibility for disabled users, and additional design support for new products.</p>
                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>

                <div className="col-sm-4 col-xs-12">
                  <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                    <img alt="" src={ExperianAncestry2} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>


              </div>
              </Link>
              <Link to="/casestudy/ancestry-flowmapper/" className="" >
              <div className="row">

                <div className="feature col-sm-8 col-xs-12">
                  <h2 className="experian-heading"><strong><span className="numbers">3</span> Detailed Mapping of the Ancestry Customer Journey</strong></h2>
                  <p>Ancestry is a complex product that millions of people use daily in very different ways. The digital journey map helped build a common understanding of how the product worked. It helped us identify areas for improvement, and helped to educate new employees on how the whole system functions.</p>

                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>
                <div className="feature col-sm-4 col-xs-12">
                  <ScrollAnimation animateIn="fadeInRight" duration={1}>
                    <img alt="" src={ExperianAncestry3} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>
              </Link>
              <Link to="/casestudy/ancestry-new-directions/" className="" >
              <div className="row">
                <div className="feature col-sm-8 col-xs-12 last-sm">
                  <h2 className="experian-heading"><strong><span className="numbers">4</span>New Directions for Ancestry</strong></h2>
                  <p>Throughout my employment at Ancestry, I lead and participated in the generation of hundreds of concepts for new products. Many ideas remain only as sketches, and many have made their way into the product in one form or another. I am as comfortable with markers and paper as I am on the computer.</p>
                  <p><img alt="" src={ResumeIcon} style={{"width":".75em", "verticalAlign":"middle","marginRight":".25em"}}/> <strong>Read This Case Study</strong></p>
                </div>
                <div className="feature col-sm-4 col-xs-12">
                  <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                    <img alt="" src={ExperianAncestry4} style={{"objectFit":"cover","width":"100%"}}/>
                  </ScrollAnimation>
                </div>
              </div>
              </Link>
            </div>
          </section>

          <section className="testimonial mt-100">
            <div className="testimonial-wrapper">

            <ScrollAnimation animateIn="fadeIn" duration={.5}>

            <div className="testimonial">
            <p>“You rarely find this much talent and expertise in a single individual. John is exceptional at strategizing, concepting, articulating, managing, and executing top-notch user experience design. He's a walking wiki on the theory of design and user experience. He collaborates really well, he's affable and friendly to work with, and somehow he manages to do all this while expecting nothing short of great design from those around him. He inspires team members and peers to deliver high-quality product experiences.</p>
            <p>In short, John is pretty fantastic at what he does”</p>
            </div>

            <div className="testimonial-caption" style={{"verticalAlign":"middle"}}>
              <div className="avatar"><img alt="Cameron Moll" src={Moll}/></div>
              <p className="testimonial-caption"><strong>Cameron Moll</strong>
              <br/>Author, Speaker<br/> Design Leader at Facebook</p>
            </div>

          </ScrollAnimation>
          </div>
          </section>

          <section className="contentSection">

            <div className="row">
            <div className="col-xs-12 col-sm-9">
              <h1>Leadership & Management</h1>
              <p>I find most corporate manager-ly things thoroughly annoying.
However, I care lots about people, and the most rewarding benefits of my career have been watching people I’ve had the opportunity to “manage” grow into amazing designers and leaders.</p>
              <p>
              My current title is Sr. Director of User Experience,  but honestly, I’d love to see job titles, corporate hierarchies, and everything that goes with them go away.</p>

              <p>I can do my share of directing, but mostly I like working with a team to make stuff better. I have learned quite a few things in my career, including the fact that ideas are never good because of someone’s job title.</p>

            </div>

            </div>

          </section>

          <section className="contentSection">

              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <div>
                    <h1>Writing on Design & Creativity</h1>
                    <p>“Designing” is a complicated job, and designers everywhere are all trying to make sense of it. I've written some perspectives I've had about design and creativity, along with a few other stories I hope people find entertaining. <Link to="/articles" className="link">View all articles ↓</Link>
                    </p>
                  </div>
              </div>
              <div className="row">
              {posts
                .map(({ node: post, index }) => (

                  <div className="col-xs-12 col-sm-6 col-md-3" style={{"padding":"10px"}}>
                  <Link to={post.fields.slug} key={post.id}>
                    <div className="postcard" >
                      <div className="cardthumb grow">
                        <img alt="" src={post.frontmatter.thumb.childImageSharp.fluid.src}  />
                      </div>
                      <div className="cardtitle">
                        <h3 className="no-margin">
                          {post.frontmatter.title}
                        </h3>
                        <p className="opacity-half description">
                          {post.frontmatter.description}
                        </p>
                      </div>
                    </div>
                    </Link>
                    </div>

                ))}
              </div>
              <p><Link to="/articles" className="link">View all articles →</Link></p>
              </div>
            </section>


            <section className="contentSection tripSection">
              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <h1>Outside of the Workplace</h1>
                  <p>I love exploring the world and visiting beautiful places. I’m working out how I can make my job duties exclusive to exploring the great outdoors, touring the world’s most beautiful sites, investigating art museums, and cultural history.</p>
                </div>
              </div>
              <div style={{"width":"100%"}}  className="mt-60">
              <div className="row no-gap">
                {trips
                  .map(({ node: trip }) => (
                    <div className="col-xs-6 col-sm-4 col-md-4 trip grow"  style={{"height":"20vh","minHeight":"200px"}} key={trip.id}>
                      <Link to={trip.fields.slug}>
                      <Img alt="" fluid={trip.frontmatter.thumb.childImageSharp.fluid} />
                      <h2>{trip.frontmatter.title}</h2>
                      </Link>
                    </div>
                  ))}
              </div>

              <p><Link to="/trips" className="link">View all trips →</Link></p>
              </div>
            </section>



            <section className="contentSection artworkSection">
              <div className="row">
                <div className="col-xs-12 col-md-9">
                  <h1>There's more to tell</h1>
                  <p>I hope this page helps you to get to know me a little.</p>
                  <p>I can be reached anytime by email (john.dilworth@gmail.com) or on my mobile phone (801.703.3905). I'm looking forward to talking with you soon.</p>

                </div>
              </div>
            </section>
        </article>
        </div>
      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}

CollectiveMedicalPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
        edges: PropTypes.array,
    }),
    trips: PropTypes.shape({
        edges: PropTypes.array,
    }),
    artwork: PropTypes.shape({
        edges: PropTypes.array,
    }),
  }),
}

export const CollectiveMedicalQuery = graphql`
  query CollectiveMedicalQuery {
    articles:allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: "ML" }, published: {ne: false} } }
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    trips:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "trip" } }},
      limit: 9
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
    artwork:allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___order]},
        filter: { frontmatter: { templateKey: { eq: "artwork" }, collection: { eq: "inventors" } }},
        limit: 1
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
              path
              thumb{
                childImageSharp{
                  fluid(maxWidth: 500){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  }
  `
