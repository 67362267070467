import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Navbar from './Navbar'

import '../layouts/all.styl';
import '../layouts/typography.styl';
import '../layouts/flexboxgrid.styl';

class TemplateWrapper extends React.Component {

  render (){

    
    return(
      <div>
        <Helmet title="John Dilworth" />
        <Navbar />
        <div>{this.props.children}</div>
      </div>
    )
  }
}
  

TemplateWrapper.propTypes = {
  children: PropTypes.node
}

export default TemplateWrapper
